var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      class:
        !!_vm.sectionConfig.display && _vm.sectionConfig.display == "false"
          ? "multi-section-card hidden"
          : "multi-section-card",
    },
    [
      _vm.dataPoint.type == "component"
        ? _c(
            "div",
            [
              _c("research-section-components", {
                attrs: {
                  component: _vm.dataPoint.name,
                  phenotypesInUse: _vm.phenotypesInUse,
                  utilsBox: _vm.utils,
                  sectionConfigs: _vm.sectionConfig,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      (!!_vm.sectionConfig["required parameters to display"] &&
        !!_vm.meetRequirements()) ||
      !_vm.sectionConfig["required parameters to display"]
        ? _c(
            "div",
            {
              staticClass: "multi-section",
              class: "wrapper-" + _vm.sectionIndex,
            },
            [
              !_vm.isInTab
                ? _c("div", { staticClass: "row section-header" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      !!_vm.sectionData && _vm.sectionData.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm show-evidence-btn capture-data",
                              attrs: { title: "Capture data in section" },
                              on: {
                                click: function ($event) {
                                  return _vm.captureData()
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "camera" } })],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-sm show-evidence-btn show-hide-section",
                          class:
                            _vm.sectionHidden != true ? "" : "red-background",
                          attrs: { title: "Show / hide section" },
                          on: {
                            click: function ($event) {
                              _vm.utils.uiUtils.showHideSvg(
                                "section_" + _vm.sectionID
                              )
                              _vm.sectionHidden =
                                _vm.sectionHidden == true ? false : true
                            },
                          },
                        },
                        [_c("b-icon", { attrs: { icon: "eye" } })],
                        1
                      ),
                      _c(
                        "h4",
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.utils.Formatters.replaceWithParams(
                                  _vm.sectionConfig.header,
                                  _vm.pageParams
                                )
                              ),
                            },
                          }),
                          _c("research-loading-spinner", {
                            attrs: {
                              isLoading:
                                _vm.loadingDataFlag == "down" ? "" : "whatever",
                              colorStyle: "color",
                            },
                          }),
                          !!_vm.noLoadedData
                            ? _c("div", { staticClass: "no-data-flag" }, [
                                _vm._v(_vm._s(_vm.noLoadedData)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              !!_vm.isInTab
                ? _c("div", { staticClass: "row section-header" }, [
                    _c("div", { staticClass: "col-md-12" }, [
                      !!_vm.sectionData && _vm.sectionData.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn btn-sm show-evidence-btn capture-data",
                              attrs: { title: "Capture data in section" },
                              on: {
                                click: function ($event) {
                                  return _vm.captureData()
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "camera" } })],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "h4",
                        [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.utils.Formatters.replaceWithParams(
                                  _vm.sectionConfig.header,
                                  _vm.pageParams
                                )
                              ),
                            },
                          }),
                          _c("research-loading-spinner", {
                            attrs: {
                              isLoading:
                                _vm.loadingDataFlag == "down" ? "" : "whatever",
                              colorStyle: "color",
                            },
                          }),
                          !!_vm.noLoadedData
                            ? _c("div", { staticClass: "no-data-flag" }, [
                                _vm._v(_vm._s(_vm.noLoadedData)),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "row",
                  attrs: { id: "section_" + _vm.sectionID },
                },
                [
                  !!_vm.groups
                    ? _c(
                        "div",
                        { staticClass: "col-md-12" },
                        _vm._l(_vm.groups, function (key) {
                          return _c("span", {
                            staticClass:
                              "btn section-search-bbl show-evidence-btn",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.utils.Formatters.getShortName(key.label) +
                                  " x"
                              ),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.removeData(key)
                              },
                            },
                          })
                        }),
                        0
                      )
                    : _vm._e(),
                  !_vm.openInfoCard &&
                  !!_vm.sectionConfig["filters vertical"] &&
                  _vm.sectionConfig["filters vertical"]["side"] == "left"
                    ? _c(
                        "div",
                        {
                          style:
                            "width: " +
                            _vm.sectionConfig["filters vertical"]["width"] +
                            "px; margin-right: 15px",
                        },
                        [
                          !!_vm.filters
                            ? _c("research-section-filters-vertical", {
                                attrs: {
                                  filters: _vm.filters,
                                  filterWidth:
                                    _vm.sectionConfig["filter width"],
                                  dataset: _vm.sectionData,
                                  unfilteredDataset: _vm.originalData,
                                  sectionId: _vm.sectionID,
                                  utils: _vm.utils,
                                  dataComparisonConfig: null,
                                },
                                on: {
                                  "on-filtering": _vm.updateData,
                                  "clicked-sort": _vm.sortData,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class: !_vm.sectionConfig["filters vertical"]
                        ? "col-md-12 wrapper-" + _vm.sectionIndex
                        : "wrapper-" + _vm.sectionIndex,
                      style: !!_vm.sectionConfig["filters vertical"]
                        ? !_vm.openInfoCard
                          ? "width: calc(100% - " +
                            (_vm.sectionConfig["filters vertical"]["width"] +
                              15) +
                            "px);"
                          : ""
                        : "",
                    },
                    [
                      !!_vm.sectionConfig["search parameters"]
                        ? _c("research-in-section-search", {
                            class:
                              !!_vm.sectionConfig["search parameters"]
                                .display &&
                              _vm.sectionConfig["search parameters"].display ==
                                "false"
                                ? "hidden-search"
                                : "",
                            attrs: {
                              searchParameters:
                                _vm.sectionConfig["search parameters"],
                              phenotypesInUse: _vm.phenotypesInUse,
                              section: _vm.sectionConfig,
                              utils: _vm.utils,
                            },
                          })
                        : _vm._e(),
                      !!_vm.sectionDescription
                        ? _c("research-page-description", {
                            attrs: {
                              content: _vm.utils.Formatters.replaceWithParams(
                                _vm.sectionDescription,
                                _vm.pageParams
                              ),
                              utils: _vm.utils,
                            },
                          })
                        : _vm._e(),
                      !!_vm.filters && !_vm.sectionConfig["filters vertical"]
                        ? _c("research-section-filters", {
                            attrs: {
                              filters: _vm.filters,
                              filterWidth: _vm.sectionConfig["filter width"],
                              dataset: _vm.sectionData,
                              unfilteredDataset: _vm.originalData,
                              sectionId: _vm.sectionID,
                              utils: _vm.utils,
                              dataComparisonConfig: null,
                            },
                            on: {
                              "on-filtering": _vm.updateData,
                              "clicked-sort": _vm.sortData,
                            },
                          })
                        : _vm._e(),
                      !!_vm.tableFormat &&
                      !!_vm.tableFormat["data zoom"] &&
                      !!_vm.sectionData &&
                      _vm.sectionData.length > 0
                        ? _c("div", { staticClass: "zoom-ui-wrapper" }, [
                            _c("span", [_vm._v("Zoom")]),
                            _c(
                              "form",
                              { staticClass: "zoom-radio-wrapper" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "zoom-radio-number",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setZoom(
                                          "regionZoom",
                                          (_vm.regionZoom -=
                                            _vm.regionZoom != 0 ? 10 : 0)
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "zoom-out" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  [0, 10, 20, 30, 40, 50, 60, 70, 80, 90],
                                  function (value) {
                                    return _c("input", {
                                      key: value,
                                      class:
                                        _vm.regionZoom == value
                                          ? "zoom-radio checked"
                                          : "zoom-radio",
                                      attrs: {
                                        type: "radio",
                                        name: "regionZoom",
                                      },
                                      domProps: { value: value },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setZoom(
                                            "regionZoom",
                                            value
                                          )
                                        },
                                      },
                                    })
                                  }
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "zoom-radio-number",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setZoom(
                                          "regionZoom",
                                          (_vm.regionZoom +=
                                            _vm.regionZoom != 90 ? 10 : 0)
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "zoom-in" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _c("span", [_vm._v("Move viewing area")]),
                            _c(
                              "form",
                              { staticClass: "zoom-radio-wrapper" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "zoom-radio-number",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setZoom(
                                          "regionViewArea",
                                          (_vm.regionViewArea -=
                                            _vm.regionViewArea != -100 &&
                                            _vm.regionZoom != 0
                                              ? 20
                                              : 0)
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "arrow-left-circle" },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._l(
                                  [
                                    -100, -80, -60, -40, -20, 0, 20, 40, 60, 80,
                                    100,
                                  ],
                                  function (value) {
                                    return _c("input", {
                                      key: value,
                                      class:
                                        _vm.regionViewArea == value
                                          ? "zoom-radio checked"
                                          : value == 0
                                          ? "zoom-radio center"
                                          : "zoom-radio",
                                      attrs: {
                                        type: "radio",
                                        name: "regionViewArea",
                                      },
                                      domProps: { value: value },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setZoom(
                                            "regionViewArea",
                                            _vm.regionZoom != 0 ? value : ""
                                          )
                                        },
                                      },
                                    })
                                  }
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "zoom-radio-number",
                                    on: {
                                      click: function ($event) {
                                        return _vm.setZoom(
                                          "regionViewArea",
                                          (_vm.regionViewArea +=
                                            _vm.regionViewArea != 100 &&
                                            _vm.regionZoom != 0
                                              ? 20
                                              : 0)
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "arrow-right-circle" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      !!_vm.multiVisualizers &&
                      !!_vm.sectionData &&
                      _vm.multiVisualizersType == "tabs"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "sub-tab-ui-wrapper",
                                attrs: { id: "tabUiGroup" + _vm.sectionID },
                              },
                              _vm._l(
                                _vm.multiVisualizers,
                                function (tab, tabIndex) {
                                  return _c(
                                    "div",
                                    {
                                      staticClass: "tab-ui-tab",
                                      class: tabIndex == 0 ? "active" : "",
                                      attrs: {
                                        id: "tabUi" + _vm.sectionID + tabIndex,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.utils.uiUtils.setTabActive(
                                            "tabUi" + _vm.sectionID + tabIndex,
                                            "tabUiGroup" + _vm.sectionID,
                                            "tabContent" +
                                              _vm.sectionID +
                                              tabIndex,
                                            "tabContentGroup" + _vm.sectionID,
                                            true
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.utils.Formatters.replaceWithParams(
                                              tab.label,
                                              _vm.pageParams
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        : _vm._e(),
                      !!_vm.vizGroups &&
                      !!_vm.sectionData &&
                      _vm.multiVisualizersType == "grouped tabs"
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "sub-tab-ui-wrapper",
                                attrs: { id: "tabUiGroup" + _vm.sectionID },
                              },
                              _vm._l(_vm.vizGroups, function (tab, tabIndex) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "tab-ui-tab",
                                    class: tabIndex == 0 ? "active" : "",
                                    attrs: {
                                      id: "tabUi" + _vm.sectionID + tabIndex,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.utils.uiUtils.setTabActive(
                                          "tabUi" + _vm.sectionID + tabIndex,
                                          "tabUiGroup" + _vm.sectionID,
                                          "tabContent" +
                                            _vm.sectionID +
                                            tabIndex,
                                          "tabContentGroup" + _vm.sectionID,
                                          true
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.utils.Formatters.replaceWithParams(
                                            tab.label,
                                            _vm.pageParams
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        : _vm._e(),
                      !!_vm.vizGroups &&
                      !!_vm.sectionData &&
                      _vm.multiVisualizersType == "grouped tabs"
                        ? _c(
                            "div",
                            {
                              attrs: {
                                id:
                                  _vm.multiVisualizersType == "grouped tabs"
                                    ? "tabContentGroup" + _vm.sectionID
                                    : "",
                              },
                            },
                            _vm._l(_vm.vizGroups, function (group, groupIndex) {
                              return _c(
                                "div",
                                {
                                  staticClass: "plot-tab-content-wrapper",
                                  class:
                                    groupIndex == 0 ? "" : "hidden-content",
                                  attrs: {
                                    id:
                                      "tabContent" + _vm.sectionID + groupIndex,
                                  },
                                },
                                _vm._l(
                                  group.visualizers,
                                  function (plotConfig, plotIndex) {
                                    return _c(
                                      "div",
                                      { staticClass: "plot-content-wrapper" },
                                      [
                                        _c("h6", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.utils.Formatters.replaceWithParams(
                                                plotConfig.label,
                                                _vm.pageParams
                                              )
                                            ),
                                          },
                                        }),
                                        _c("research-section-visualizers", {
                                          attrs: {
                                            plotConfig: plotConfig,
                                            plotData:
                                              !_vm.groups ||
                                              (!!_vm.groups &&
                                                _vm.groups.length <= 1) ||
                                              !_vm.dataComparisonConfig
                                                ? _vm.sectionData
                                                : _vm.mergedData,
                                            phenotypeMap: _vm.phenotypeMap,
                                            colors: _vm.colors,
                                            plotMargin: _vm.plotMargin,
                                            plotLegend:
                                              _vm.getSectionPlotLegend(
                                                _vm.sectionID +
                                                  groupIndex +
                                                  "_" +
                                                  plotIndex
                                              ),
                                            sectionId:
                                              _vm.sectionID +
                                              groupIndex +
                                              "_" +
                                              plotIndex,
                                            utils: _vm.utils,
                                            dataComparisonConfig:
                                              _vm.dataComparisonConfig,
                                            searchParameters:
                                              _vm.groupSearchParameters,
                                            regionZoom: _vm.regionZoom,
                                            regionViewArea: _vm.regionViewArea,
                                            region: _vm.regionParam,
                                            starItems: _vm.starItems,
                                          },
                                          on: { "on-star": _vm.starColumn },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                0
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                      !!_vm.multiVisualizers &&
                      !!_vm.sectionData &&
                      (_vm.multiVisualizersType == "tabs" ||
                        _vm.multiVisualizersType == "divs")
                        ? _c(
                            "div",
                            {
                              attrs: {
                                id:
                                  _vm.multiVisualizersType == "tabs"
                                    ? "tabContentGroup" + _vm.sectionID
                                    : "",
                              },
                            },
                            _vm._l(
                              _vm.multiVisualizers,
                              function (plotConfig, plotIndex) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "plot-tab-content-wrapper",
                                    class:
                                      _vm.multiVisualizersType == "tabs"
                                        ? plotIndex == 0
                                          ? ""
                                          : "hidden-content"
                                        : "",
                                    attrs: {
                                      id:
                                        _vm.multiVisualizersType == "tabs"
                                          ? "tabContent" +
                                            _vm.sectionID +
                                            plotIndex
                                          : "",
                                    },
                                  },
                                  [
                                    _vm.multiVisualizersType != "tabs"
                                      ? _c("h6", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.utils.Formatters.replaceWithParams(
                                                plotConfig.label,
                                                _vm.pageParams
                                              )
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    _c("research-section-visualizers", {
                                      attrs: {
                                        plotConfig: plotConfig,
                                        plotData:
                                          !_vm.groups ||
                                          (!!_vm.groups &&
                                            _vm.groups.length <= 1) ||
                                          !_vm.dataComparisonConfig
                                            ? _vm.sectionData
                                            : _vm.mergedData,
                                        phenotypeMap: _vm.phenotypeMap,
                                        colors: _vm.colors,
                                        plotMargin: _vm.plotMargin,
                                        plotLegend: _vm.getSectionPlotLegend(
                                          _vm.sectionID + plotIndex
                                        ),
                                        sectionId: _vm.sectionID + plotIndex,
                                        utils: _vm.utils,
                                        dataComparisonConfig:
                                          _vm.dataComparisonConfig,
                                        searchParameters:
                                          _vm.groupSearchParameters,
                                        regionZoom: _vm.regionZoom,
                                        regionViewArea: _vm.regionViewArea,
                                        region: _vm.regionParam,
                                        starItems: _vm.starItems,
                                      },
                                      on: { "on-star": _vm.starColumn },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      !_vm.multiVisualizers &&
                      !!_vm.visualizer &&
                      !!_vm.sectionData
                        ? _c("research-section-visualizers", {
                            attrs: {
                              plotConfig: _vm.visualizer,
                              plotData:
                                !_vm.groups ||
                                (!!_vm.groups && _vm.groups.length <= 1) ||
                                !_vm.dataComparisonConfig
                                  ? _vm.sectionData
                                  : _vm.mergedData,
                              phenotypeMap: _vm.phenotypeMap,
                              colors: _vm.colors,
                              plotMargin: _vm.plotMargin,
                              plotLegend: _vm.getSectionPlotLegend(
                                _vm.sectionID
                              ),
                              sectionId: _vm.sectionID,
                              utils: _vm.utils,
                              dataComparisonConfig: _vm.dataComparisonConfig,
                              searchParameters: _vm.groupSearchParameters,
                              regionZoom: _vm.regionZoom,
                              regionViewArea: _vm.regionViewArea,
                              region: _vm.regionParam,
                              starItems: _vm.starItems,
                            },
                            on: { "on-star": _vm.starColumn },
                          })
                        : _vm._e(),
                      !!_vm.tableFormat && !_vm.tableFormat["display type"]
                        ? [
                            !!_vm.tableFormat &&
                            !_vm.tableFormat["rows as info cards"]
                              ? _c("research-data-table", {
                                  attrs: {
                                    pageID: _vm.sectionIndex,
                                    dataset:
                                      !_vm.groups ||
                                      (!!_vm.groups &&
                                        _vm.groups.length <= 1) ||
                                      !_vm.dataComparisonConfig
                                        ? _vm.sectionData
                                        : _vm.mergedData,
                                    tableFormat: _vm.tableFormat,
                                    initPerPageNumber: !!_vm.tableFormat[
                                      "rows per page"
                                    ]
                                      ? _vm.tableFormat["rows per page"]
                                      : 10,
                                    tableLegend: _vm.getSectionTableLegend(
                                      _vm.sectionID
                                    ),
                                    dataComparisonConfig:
                                      _vm.dataComparisonConfig,
                                    searchParameters: _vm.groupSearchParameters,
                                    pkgData: null,
                                    pkgDataSelected: null,
                                    phenotypeMap: _vm.phenotypeMap,
                                    sectionId: _vm.sectionID,
                                    multiSectionPage: true,
                                    starItems: _vm.starItems,
                                    utils: _vm.utils,
                                    region: _vm.regionParam,
                                    regionZoom: _vm.regionZoom,
                                    regionViewArea: _vm.regionViewArea,
                                    colors: _vm.colors,
                                    plotMargin: _vm.plotMargin,
                                  },
                                  on: {
                                    "clicked-sort": _vm.sortData,
                                    "on-star": _vm.starColumn,
                                    "on-filtering": _vm.updateData,
                                  },
                                })
                              : _vm._e(),
                            !!_vm.tableFormat &&
                            !!_vm.tableFormat["rows as info cards"]
                              ? _c("research-info-cards", {
                                  attrs: {
                                    pageID: _vm.sectionIndex,
                                    dataset:
                                      !_vm.groups ||
                                      (!!_vm.groups &&
                                        _vm.groups.length <= 1) ||
                                      !_vm.dataComparisonConfig
                                        ? _vm.sectionData
                                        : _vm.mergedData,
                                    tableFormat: _vm.tableFormat,
                                    initPerPageNumber: !!_vm.tableFormat[
                                      "rows per page"
                                    ]
                                      ? _vm.tableFormat["rows per page"]
                                      : 10,
                                    tableLegend: _vm.getSectionTableLegend(
                                      _vm.sectionID
                                    ),
                                    dataComparisonConfig:
                                      _vm.dataComparisonConfig,
                                    searchParameters: _vm.groupSearchParameters,
                                    pkgData: null,
                                    pkgDataSelected: null,
                                    phenotypeMap: _vm.phenotypeMap,
                                    sectionId: _vm.sectionID,
                                    multiSectionPage: true,
                                    starItems: _vm.starItems,
                                    utils: _vm.utils,
                                    thumbnailWidth:
                                      !!_vm.sectionConfig["filters vertical"] &&
                                      !!_vm.sectionConfig["filters vertical"][
                                        "width"
                                      ]
                                        ? _vm.sectionConfig["filters vertical"][
                                            "width"
                                          ]
                                        : 250,
                                    region: _vm.regionParam,
                                    regionZoom: _vm.regionZoom,
                                    regionViewArea: _vm.regionViewArea,
                                    openCardPreset: _vm.openInfoCard,
                                  },
                                  on: {
                                    "clicked-sort": _vm.sortData,
                                    "on-star": _vm.starColumn,
                                    "on-filtering": _vm.updateData,
                                    "on-openCard": _vm.setOpenInfoCard,
                                  },
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      !!_vm.tableFormat && !!_vm.tableFormat["display type"]
                        ? [
                            _vm.tableFormat["display type"] == "tabs"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "sub-tab-ui-wrapper",
                                      attrs: {
                                        id: "tableTabs" + _vm.sectionID,
                                      },
                                    },
                                    _vm._l(
                                      _vm.tableFormat["tabs"],
                                      function (tab, tabIndex) {
                                        return _c("div", {
                                          staticClass: "tab-ui-tab",
                                          class: tabIndex == 0 ? "active" : "",
                                          attrs: {
                                            id:
                                              "tabUi" +
                                              _vm.sectionID +
                                              tab["id"],
                                          },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.utils.Formatters.replaceWithParams(
                                                tab["header"],
                                                _vm.pageParams
                                              )
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.utils.uiUtils.setTabActive(
                                                "tabUi" +
                                                  _vm.sectionID +
                                                  tab["id"],
                                                "tableTabs" + _vm.sectionID,
                                                "tableContent" +
                                                  _vm.sectionID +
                                                  tab["id"],
                                                "tableContentGroup" +
                                                  _vm.sectionID,
                                                false
                                              )
                                            },
                                          },
                                        })
                                      }
                                    ),
                                    0
                                  ),
                                  _c(
                                    "div",
                                    {
                                      attrs: {
                                        id: "tableContentGroup" + _vm.sectionID,
                                      },
                                    },
                                    _vm._l(
                                      _vm.tableFormat["tabs"],
                                      function (tab, tabIndex) {
                                        return _c(
                                          "div",
                                          {
                                            staticClass: "tab-content-wrapper",
                                            class:
                                              tabIndex == 0
                                                ? ""
                                                : "hidden-content",
                                            attrs: {
                                              id:
                                                "tableContent" +
                                                _vm.sectionID +
                                                tab["id"],
                                            },
                                          },
                                          [
                                            !!_vm.sectionData
                                              ? _c("research-data-table", {
                                                  attrs: {
                                                    pageID: _vm.sectionIndex,
                                                    dataset: _vm.buildTabData(
                                                      _vm.sectionData,
                                                      tab
                                                    ),
                                                    tableFormat:
                                                      _vm.buildTabFormat(
                                                        _vm.tableFormat,
                                                        tab
                                                      ),
                                                    initPerPageNumber: !!_vm
                                                      .tableFormat[
                                                      "rows per page"
                                                    ]
                                                      ? _vm.tableFormat[
                                                          "rows per page"
                                                        ]
                                                      : 10,
                                                    tableLegend:
                                                      _vm.getSectionTableLegend(
                                                        _vm.sectionID
                                                      ),
                                                    dataComparisonConfig:
                                                      _vm.dataComparisonConfig,
                                                    searchParameters:
                                                      _vm.groupSearchParameters,
                                                    pkgData: null,
                                                    pkgDataSelected: null,
                                                    phenotypeMap:
                                                      _vm.phenotypeMap,
                                                    sectionId: _vm.sectionID,
                                                    multiSectionPage: true,
                                                    starItems: _vm.starItems,
                                                    utils: _vm.utils,
                                                    region: _vm.regionParam,
                                                    regionZoom: _vm.regionZoom,
                                                    regionViewArea:
                                                      _vm.regionViewArea,
                                                    colors: _vm.colors,
                                                    plotMargin: _vm.plotMargin,
                                                  },
                                                  on: {
                                                    "clicked-sort":
                                                      _vm.sortData,
                                                    "on-star": _vm.starColumn,
                                                    "on-filtering":
                                                      _vm.updateData,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  !_vm.openInfoCard &&
                  !!_vm.sectionConfig["filters vertical"] &&
                  _vm.sectionConfig["filters vertical"]["side"] == "right"
                    ? _c(
                        "div",
                        {
                          staticClass: "vertical-filter",
                          style:
                            "width: " +
                            _vm.sectionConfig["filters vertical"]["width"] +
                            "px;margin-left: 15px;",
                        },
                        [
                          !!_vm.filters
                            ? _c("research-section-filters-vertical", {
                                attrs: {
                                  filters: _vm.filters,
                                  filterWidth:
                                    _vm.sectionConfig["filter width"],
                                  dataset: _vm.sectionData,
                                  unfilteredDataset: _vm.originalData,
                                  sectionId: _vm.sectionID,
                                  utils: _vm.utils,
                                  dataComparisonConfig: null,
                                },
                                on: {
                                  "on-filtering": _vm.updateData,
                                  "clicked-sort": _vm.sortData,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }